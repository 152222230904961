import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Navigation } from "./";

function PhotoFact(props) {
  return (
    <div className="postphoto">
      {/* <Router >
      <Navigation />
      </Router> */}

      {/* META TAG AND META TITTLE */}

      <Helmet>
        <title>5 Interesting Photo Editing Facts - CutOutWiz</title>
        <meta
          name="description"
          keywords="Food Photography, Beginner photography"
          content="Photographing food lets you play with the ingredients and design the setting that would be most appealing to you. Food photographs bring great joy to both the photographer and the viewer."
        />
      </Helmet>

      <div
        id="button_back"
        style={{
          marginTop: "9%",
          backgroundColor: "#211f40",
          height: "100%",
          width: "100%",
        }}
      >
        {/* BLOG TITTLE */}

        <p
          id="headpost"
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "50px",
            top: "50%",
            fontWeight: "200",
          }}
        >
          5 Interesting Photo Editing Facts
        </p>
      </div>

      <div class="container" style={{ marginTop: "4%" }}>
        <div class="row">
          <div class="col-md-8" style={{ height: "max-content" }}>
            {/* BANNER IMAGE */}
            <img
              src="../../assets/images/Blog 18.jpg"
              width="100%"
              height="20%"
              alt="sqaure-image-excited-man-with-camera-laptop-stationaries-blue-headphone-scissors-paper-clip-board"
            />
            {/* FIRST PARAGRAPH */}
            <p
              style={{
                textAlign: "justify",
                marginTop: "4%",
                fontFamily: "poppins",
              }}
            >
              To most of us, the idea of photo editing is similar to having a
              magic wand. It feels as though editors are the fairy godmothers of
              e-commerce and when they point their wands at a product photo and
              say -“bibidi babidi boo”- even a scraped good will turn into
              something gorgeous. But is the idea really accurate?
              <br />
              <br />
              We often perceive editing as a way to distort viewers from the
              reality and present them with something fake instead. Well, this
              blog is here to get rid of all your misconceptions and present you
              with some interesting facts about photo editing. So buckle up and
              get ready to be amazed!{" "}
            </p>
            <img
              src="../../assets/images/computer-laptop-research-working-desk-concept.jpg"
              class="rounded mx-auto d-block"
              width="80%"
              height="20%"
              alt="computer-laptop-research-working-desk-concept"
            />
            <br />
            {/* QUOTE  */}
            <i style={{ fontSize: "20px", fontWeight: "700" }}>
              “You don’t take a photograph, you make it”{" "}
            </i>{" "}
            <br /> <br />
            {/* WRITTER NAME NAD DESIGNATION*/}
            <p
              style={{
                textAlign: "right",
                fontFamily: "poppins",
                fontSize: "18px",
              }}
            >
              -Ansel Adams <br />
              American Photographer
            </p>
            {/* SECOND PARAGRAPH */}
            <p
              style={{
                textAlign: "justify",
                marginTop: "4%",
                fontFamily: "poppins",
              }}
            >
              Preparing an appropriate set up and taking photographs is just
              half of the photo making process. In the other half, you polish
              and modify the photo through photo editing to help it become its
              beautiful self.
              <br />
              <br />
              Product photos are the heart of an e-commerce website. Ensuring
              perfection in product photos is a necessity we all must abide by.
              But even if we try our hardest, we may not be able to ensure
              perfection in every photograph. Some flaws are bound to persist.
              Perhaps the lights won’t seem accurate in some photos; there may
              be crease or wrinkle in the product, the background may not be
              appropriate etc. This is when photo editing comes in bearing a
              torch to guide you to get high quality product photos. Follow the
              light and help your business shine!{" "}
            </p>
            <p style={{ fontSize: "21px", fontWeight: "700" }}>
              Interesting Facts about Image Editing{" "}
            </p>
            {/* SUB-HEADING */}
            <p style={{ fontSize: "18px", fontWeight: "700" }}>
              Fact 1: Photo editing doesn’t hamper the originality of the
              photograph{" "}
            </p>
            {/* DESCRIPTION */}
            <p style={{ textAlign: "justify" }}>
              Many e-commerce store owners worry that photo editing will turn
              their product photos into something it’s not. Maybe the color will
              become too bright or the photo will be manipulated to the extent
              of changing it overall. So ultimately, when customers receive the
              product, they’ll be disappointed as it’s different from what they
              saw in the picture. As a result, product returns will increase.
              While editing is capable of doing all that, the main purpose of
              editing is not to distort the originality of your photo but to
              enhance it. Editing will provide you room to make mistakes while
              photographing it. Photo editing will help you get rid of that
              wrinkle you didn't notice, or the lights you couldn't brighten,
              and retouch any other flaws that could make your clients leave
              your page without hitting on the buy button.{" "}
            </p>
            <img
              src="../../assets/images/professional-editor-doing-retouching-work-image.jpg"
              class="rounded mx-auto d-block"
              width="80%"
              height="20%"
              alt="professional-editor-doing-retouching-work-image"
            />
            <br />
            <p style={{ fontSize: "18px", fontWeight: "700" }}>
              Fact 2: Outsourcing Photo editing don’t have to be expensive{" "}
            </p>
            {/* DESCRIPTION */}
            <p style={{ textAlign: "justify" }}>
              The main intent behind outsourcing is to save time and effort that
              can be better utilized elsewhere. Whenever we outsource, we try to
              hire an expert who has better knowledge of the subject matter and
              can do a faster, better and more effective job. The same goes for
              photo editing. If you do your research well enough, you’ll be able
              to find out image editing services that will provide you with best
              quality images at a reasonable price. It’s better to outsource and
              get expert quality services rather than doing inadequate editing
              job that may not even achieve your desired results{" "}
            </p>
            {/* SUB-HEADING */}
            <p style={{ fontSize: "18px", fontWeight: "700" }}>
              Fact 3: Photo editing can’t rid you of all the imperfections{" "}
            </p>
            {/* DESCRIPTION */}
            <p style={{ textAlign: "justify" }}>
              As we emphasized earlier, photo editing can only help enhance the
              originality of your photo and not make it into something
              different. So it’s our duty that the photographs are taken
              professionally and we try to maintain as much precision as
              possible. For example- if it’s a cloth, wash and iron it properly.
              Editing won’t help if the entire cloth is full of wrinkles and
              dust. FOr examplle, you cannot expect to take a photo in a dark
              room and hope for the editing to bring it to its original color.
              At the same time, you can’t take a blurry photo and expect editing
              to clear it out. These are not reasonable expectations at all. If
              areas to fix aren’t clearly visible, there isn’t much the editor
              can do anyways.{" "}
            </p>
            <img
              src="../../assets/images/tattoed-photographer-his-creative-studio-choosing-photos-hipster-man-work-editing-shooted-images-job-trends-fashion-technology-concept-focus-hands.jpg"
              class="rounded mx-auto d-block"
              width="80%"
              height="20%"
              alt="tattoed-photographer-his-creative-studio-choosing-photos-hipster-man-work-editing-shooted-images-job-trends-fashion-technology-concept-focus-hands"
            />
            <br />
            <p style={{ fontSize: "18px", fontWeight: "700" }}>
              Fact 4: Outsourcing photo editing won’t demean your control{" "}
            </p>
            {/* DESCRIPTION */}
            <p style={{ textAlign: "justify" }}>
              We go for outsourcing to ensure expert help. It’s your business
              and knowing every part of it is your way of ensuring that
              everything’s going well. When you’re outsourcing the image editing
              for your product photos, you’ll still be the one providing
              instructions, giving feedbacks until you get the desired output.
              If you still feel insecure, prepare a contract that ensures proper
              communication between you and the image editing service so your
              standards and style are upheld and nothing goes south.{" "}
            </p>
            <p style={{ fontSize: "18px", fontWeight: "700" }}>
              Fact 5: The photo format does matter!{" "}
            </p>
            {/* DESCRIPTION */}
            <p style={{ textAlign: "justify" }}>
              The format we take photographs in matter to a great extent.
              Cameras have functionalities of their own to edit and enhance a
              photograph. The images we get in JPEG format contains such
              functionalities and are already edited to some extent by the
              camera. We appreciate these inherent editing features when we’re
              taking personal photographs that won’t go for editing. But when
              we’re taking product photos for our business that will be edited
              later, it’s better to go with the RAW format. The RAW format
              contains more data and is not pre-edited in any way. So we’ll be
              working with a fresh bunch that can be edited as per our
              requirement.
              <br />
              <br />
              Photographing a product provides you only with half of the picture
              that can’t be completed without proper photo editing. Beautiful
              RAW photographs will take you to the interval stage but the
              climax, happy ending and the bow you take in front of your
              audience comes after you let your photos grow to become artistic
              through photo editing. You can go for in-house photo editing to
              ensure you requirements and control remains intact. On the other
              hand, photo editing services can help you get the expert view you
              need to draw in more customers and generate higher sales. Either
              way, editing is clearly a must and can’t be taken for granted.{" "}
            </p>
          </div>

          {/* END */}

          <div className="col-md-4">
            <div class="row">
              <div class="col-4 col-md-4">
                <div class="row-8">
                  <img
                    src="../../assets/images/types-of-photography.jpg"
                    width="100%"
                    height="100%"
                    alt=""
                  />
                </div>

                {/* <div class="row-4"style={{marginTop:"37%"}}>
            <img src="assets/images/latestblog.jpg"  width="100%" height="100%" alt=""/>
            </div> */}
              </div>

              <div class="col-8 col-md-8">
                <div class="row-4">
                  <Link
                    to="/types-of-photography-which-one-drive-your-inner-passion-most"
                    style={{ textDecoration: "none" }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        fontFamily: "poppins",
                        color: "black",
                      }}
                    >
                      Types Of Photography: Which One Drives Your Inner Passion
                      Most?
                    </p>
                  </Link>
                </div>
                <div class="row-8">
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "100",
                      opacity: "0.6",
                    }}
                  >
                    Photographs are our way of capturing the world as we see it.
                    It’s a medium of expressing our inner thoughts and holding
                    onto memories close
                  </p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-4 col-md-4">
                <div class="row-8">
                  <img
                    src="../../assets/images/latestblog.jpg"
                    width="100%"
                    height="100%"
                    alt=""
                  />
                </div>
              </div>

              <div class="col-8 col-md-8">
                <div class="row-4">
                  <Link
                    to="/7-creative-winter-marketing-ideas-for-e-commerce-businesses"
                    style={{ textDecoration: "none" }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        fontFamily: "poppins",
                        color: "black",
                      }}
                    >
                      7 Creative Winter Marketing Ideas For E-Commerce
                      Businesses
                    </p>
                  </Link>
                </div>
                <div class="row-8">
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "100",
                      opacity: "0.6",
                    }}
                  >
                    The beginning of winter fills our minds with numerous
                    excitements. It’s the season of Christmas, snowfall, and New
                    Year. With a sudden change in weather
                  </p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-4 col-md-4">
                <div class="row-8">
                  <img
                    src="../../assets/images/Blog-21.jpg"
                    width="100%"
                    height="100%"
                    alt=""
                  />
                </div>

                {/* <div class="row-4"style={{marginTop:"37%"}}>
            <img src="assets/images/latestblog.jpg"  width="100%" height="100%" alt=""/>
            </div> */}
              </div>

              <div class="col-8 col-md-8">
                <div class="row-4">
                  <Link
                    to="/top-shopify-marketing-strategy-for-sellers"
                    style={{ textDecoration: "none" }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        fontFamily: "poppins",
                        color: "black",
                      }}
                    >
                      Top Shopify Marketing Strategy For Sellers
                    </p>
                  </Link>
                </div>
                <div class="row-8">
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "100",
                      opacity: "0.6",
                    }}
                  >
                    E-commerce platforms like Shopify are ultimate friends of
                    small business owners. The platform allows you to build your
                    own website and shine in the world
                  </p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-4 col-md-4">
                <div class="row-8">
                  <img
                    src="../../assets/images/Blogpost.jpg"
                    width="100%"
                    height="100%"
                    alt=""
                  />
                </div>

                {/* <div class="row-4"style={{marginTop:"37%"}}>
            <img src="assets/images/latestblog.jpg"  width="100%" height="100%" alt=""/>
            </div> */}
              </div>

              <div class="col-8 col-md-8">
                <div class="row-4">
                  <Link
                    to="/5-important-photography-tips-for-beginners"
                    style={{ textDecoration: "none" }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        fontFamily: "poppins",
                        color: "black",
                      }}
                    >
                      5 Important Photography Tips For Beginners
                    </p>
                  </Link>
                </div>
                <div class="row-8">
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "100",
                      opacity: "0.6",
                    }}
                  >
                    Photographs help us freeze a certain moment and then relive
                    it again and again. It enables us to capture our happiest
                    memories into a frame
                  </p>
                </div>
              </div>
            </div>
            <br />
            <br />
            <p
              style={{
                fontWeight: "700",
                fontSize: "20px",
                fontFamily: "poppins",
              }}
            >
              Share:
            </p>
            <hr
              style={{
                width: "80%",
                border: "3px solid",
                backgroundColor: "black",
                opacity: "1",
                marginTop: "-3%",
              }}
            ></hr>

            <div class="row">
              <div class="col-4 col-md-4">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://rayyanmustaqbil.xyz/5-important-photography-tips-for-beginners/"
                  target="_blank"
                >
                  <img
                    src="../../assets/images/facebookblack.png"
                    height="100%"
                    width="50%"
                    alt=""
                  />
                </a>
              </div>

              <div class="col-4 col-md-4">
                <a
                  href="https://twitter.com/intent/tweet?text=%20https://rayyanmustaqbil.xyz/5-important-photography-tips-for-beginners/"
                  target="_blank"
                >
                  {" "}
                  <img
                    src="../../assets/images/twit.png"
                    height="100%"
                    width="50%"
                    alt=""
                  />
                </a>
              </div>

              <div class="col-4 col-md-4">
                <a
                  href="    https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2FshareArticle%3Fmini%3Dtrue%26url%3Dhttps%3A%2F%2Frayyanmustaqbil.xyz%2F5-important-photography-tips-for-beginners%2F%26title%3D5%2520Important%2520Photography%2520Tips%2520for%2520Beginners%2520%25E2%2580%2593%2520CutOutWiz%26summary%3D%26source%3Dhttps%3A%2F%2Frayyanmustaqbil.xyz%2F5-important-photography-tips-for-beginners%2F"
                  target="_blank"
                >
                  {" "}
                  <img
                    src="../../assets/images/linkdn.png"
                    height="100%"
                    width="50%"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <p
        style={{
          textAlign: "center",
          color: "white",
          padding: "8px",
          backgroundColor: "black",
        }}
      >
       Designed And Developed By Rayyan Mustaqbil
      </p>
    </div>
  );
}

export default PhotoFact;
