import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Navigation } from "./";

function OutsourcePhoto(props) {
  return (
    <div className="postphoto">
      {/* <Router >
      <Navigation />
      </Router> */}

      {/* META TAG AND META TITTLE */}

      <Helmet>
        <title>Why You Should Outsource Your Image Editing - CutOutWiz</title>
        <meta
          name="description"
          keywords="Food Photography, Beginner photography"
          content="Photographing food lets you play with the ingredients and design the setting that would be most appealing to you. Food photographs bring great joy to both the photographer and the viewer."
        />
      </Helmet>

      <div
        id="button_back"
        style={{
          marginTop: "9%",
          backgroundColor: "#211f40",
          height: "100%",
          width: "100%",
        }}
      >
        {/* BLOG TITTLE */}

        <p
          id="headpost"
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "50px",
            top: "50%",
            fontWeight: "200",
          }}
        >
          Why You Should Outsource Your Image Editing{" "}
        </p>
      </div>

      <div class="container" style={{ marginTop: "4%" }}>
        <div class="row">
          <div class="col-md-8" style={{ height: "max-content" }}>
            {/* BANNER IMAGE */}

            <img
              src="../../assets/images/Outsource-image-editing-Cover-Instagram.jpg"
              width="100%"
              height="20%"
              alt="sqaure-image-marketing-info-why-you-should-outsource-your-image-editing-blue-yellow-clock-busy-people"
            />

            <p style={{ fontSize: "18px", fontWeight: "700" }}>
              DID YOU KNOW? 
            </p>

            {/* DESCRIPTION */}

            <p
              style={{
                textAlign: "justify",
                fontSize: "18px",
                fontWeight: "700",
              }}
            >
              The work that is carried out in a developed country like the USA
              at high expense, the same work can be carried out in other
              developing countries cheaply maintaining supreme quality. 
            </p>

            <p style={{ fontSize: "18px", fontWeight: "700" }}>
              Benefits of Outsourcing you Image Post-Processing 
            </p>

            {/* DESCRIPTION */}

            <p style={{ textAlign: "justify" }}>
              Image editing is a very complex and exhausting work. If you want
              to do it in-house then you need to develop the infrastructure,
              software and resources for it separately. Outsourcing your photo
              editing task can help you to reduce your large amount of cost
              and make your work easy to handle. 
            </p>

            <img
              src="../../assets/images/Benefits of outsourcing.png"
              class="rounded mx-auto d-block"
              width="80%"
              height="20%"
              alt="Benefits of outsourcing"
            />
            <br />

            {/* SUB-HEADING */}

            <p style={{ fontSize: "18px", fontWeight: "700" }}>
              Benefits of Outsourcing you Image Editing{" "}
            </p>

            <ol>
              <li>
                <p style={{ fontSize: "16px", fontWeight: "700" }}>
                  Save Money on Developing Infrastructure{" "}
                </p>
                <p style={{ textAlign: "justify" }}>
                  To build your own photo editing department, you need to spend
                  thousands of dollars for business processes and development.
                  By outsourcing your photo editing services, you can definitely
                  save the money you spend on expensive photo equipment and
                  photo editing software.
                  <br />
                  <br />
                  It also helps you get an indirect access to all the latest and
                  developed equipment.{" "}
                </p>
              </li>

              <li>
                <p style={{ fontSize: "16px", fontWeight: "700" }}>
                  {" "}
                  Increased in ROI{" "}
                </p>
                <p style={{ textAlign: "justify" }}>
                  Your business will have a higher return on investment (ROI)
                  because you spend less money on photo editing. So, you can
                  invest more capital in developing your business. Which
                  translates into a higher return on investment (ROI) for every
                  dollar spent expanding your business. Your business will
                  surely gain a competitive advantage by saving money and
                  getting faster photo editing service.
                </p>
              </li>

              <li>
                <p style={{ fontSize: "16px", fontWeight: "700" }}>
                  Saving Time{" "}
                </p>
                <p style={{ textAlign: "justify" }}>
                  Editing images is a time-consuming task. If you edit all of
                  your business photos yourself, you will spend your valuable
                  core time editing photos, which you can use to improve your
                  business processes such as HR, manufacturing, sourcing, sales,
                  and marketing. Therefore, outsourcing image processing helps
                  steer the vital energy of your business in the right
                  direction.{" "}
                </p>
              </li>

              <li>
                <p style={{ fontSize: "16px", fontWeight: "700" }}>
                  Become Beneficiary by Getting Experts Service{" "}
                </p>
                <p style={{ textAlign: "justify" }}>
                  Companies that are working in the image processing industry
                  have many years of experience and trained professionals in all
                  types of image processing tasks. Such as background removal,
                  photo retouching, clipping path, color correction, image
                  processing, etc. With one of these companies, you will receive
                  expert advice and support, as well as expert advice and image
                  processing. Your needs, your experience and expertise will
                  help you get high quality photos for your business.{" "}
                </p>
              </li>

              <li>
                <p style={{ fontSize: "16px", fontWeight: "700" }}>
                  Bringing New Customers through Creating Positive Impact{" "}
                </p>
                <p style={{ textAlign: "justify" }}>
                  When you transfer your image editing work to another company,
                  you gain more time and can spend more time selling and
                  marketing your products in order to attract new customers. If
                  you are a photographer, having a perfectly edited photo with
                  the shortest possible time limit will help you build a good
                  relationship with your client. You also have more time to take
                  photos than before. The perfect photo of your product will
                  help you create appeal and develop positive opinions for your
                  customers if you are an e-commerce company or sell products
                  through an online platform. It will help you improve your
                  brand recognition due to its consistency in colors and
                  graphics. To know more about the how creativity in your photos
                  will attract new customers read our blog
                  <a
                    href="/product-photography-how-to-take-photos-for-your-e-commerce-business"
                    target="none"
                  >
                    {" "}
                    Importance Of Creative Image Editing For Product Photos.
                  </a>{" "}
                </p>
              </li>

              <li>
                <p style={{ fontSize: "16px", fontWeight: "700" }}>
                  Building Relationship, Security, Reliability Over Business
                  Partners{" "}
                </p>
                <p style={{ textAlign: "justify" }}>
                  Companies which will provide you image processing services
                  offer reliability and security over the image editing services
                  that they provide to the clients. These companies can also
                  ensure legal measures regarding breaking terms and conditions.
                  So, you can use the image editing services with the guarantee
                  of security. Therefore, such a photo editing service is safe
                  and beneficial for the businesses that use the service.{" "}
                </p>
              </li>
            </ol>

            <p style={{ fontSize: "18px", fontWeight: "700" }}>
              How to Outsource Your Images?{" "}
            </p>

            <img
              src="../../assets/images/How to outsource.png"
              class="rounded mx-auto d-block"
              width="80%"
              height="20%"
              alt="blue-figures-boy-girl-laptop-animation-paper-square-image"
            />
            <br />

            <p style={{ textAlign: "justify" }}>
              Now that you already know the benefits of outsourcing photo
              editing. Here’s how to find the best option for you and your
              business. Whether you're trying to grow your business online or
              you're a prolific commercial photographer on a busy schedule,
              choosing a photo editing service provider can be a daunting task.
              Whether you want to grow an online business or you’re a prolific
              commercial photographer on a busy schedule, choosing a photo
              editing service provider is a challenging task.
              <br />
              <br />
              Outsourcing refers to an agreement between two parties in which
              one party obtains professional services from the other party.
              Although they have the ability to do it by an in-house team. If
              image processing services are outsourced, the agreement will be
              done with a professional image editing company. The agreement
              includes the scope of services, the type of work to be carried
              out, service fees and expected results after completion. This
              agreement is in the interests and benefits of both parties.
              <br />
              <br />
              So far, we've discussed the seven key benefits of outsourcing your
              image editing task to a professional image editing service
              provider through your in-house image editing team. In conclusion,
              we can say that in-house photo editing is not the best option if
              you want to be competitive in your business. As outsourcing saves
              your valuable time, money, and resources. Beside that you will be
              able get brand recognition. You can use your additional resources
              to improve the quality of product and customer service.{" "}
            </p>
          </div>

          {/* END */}

          <div className="col-md-4">
            <div class="row">
              <div class="col-4 col-md-4">
                <div class="row-8">
                  <img
                    src="../../assets/images/types-of-photography.jpg"
                    width="100%"
                    height="100%"
                    alt=""
                  />
                </div>

                {/* <div class="row-4"style={{marginTop:"37%"}}>
            <img src="assets/images/latestblog.jpg"  width="100%" height="100%" alt=""/>
            </div> */}
              </div>

              <div class="col-8 col-md-8">
                <div class="row-4">
                  <Link
                    to="/types-of-photography-which-one-drive-your-inner-passion-most"
                    style={{ textDecoration: "none" }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        fontFamily: "poppins",
                        color: "black",
                      }}
                    >
                      Types Of Photography: Which One Drives Your Inner Passion
                      Most?
                    </p>
                  </Link>
                </div>
                <div class="row-8">
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "100",
                      opacity: "0.6",
                    }}
                  >
                    Photographs are our way of capturing the world as we see it.
                    It’s a medium of expressing our inner thoughts and holding
                    onto memories close
                  </p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-4 col-md-4">
                <div class="row-8">
                  <img
                    src="../../assets/images/latestblog.jpg"
                    width="100%"
                    height="100%"
                    alt=""
                  />
                </div>
              </div>

              <div class="col-8 col-md-8">
                <div class="row-4">
                  <Link
                    to="/7-creative-winter-marketing-ideas-for-e-commerce-businesses"
                    style={{ textDecoration: "none" }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        fontFamily: "poppins",
                        color: "black",
                      }}
                    >
                      7 Creative Winter Marketing Ideas For E-Commerce
                      Businesses
                    </p>
                  </Link>
                </div>
                <div class="row-8">
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "100",
                      opacity: "0.6",
                    }}
                  >
                    The beginning of winter fills our minds with numerous
                    excitements. It’s the season of Christmas, snowfall, and New
                    Year. With a sudden change in weather
                  </p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-4 col-md-4">
                <div class="row-8">
                  <img
                    src="../../assets/images/Blog-21.jpg"
                    width="100%"
                    height="100%"
                    alt=""
                  />
                </div>

                {/* <div class="row-4"style={{marginTop:"37%"}}>
            <img src="assets/images/latestblog.jpg"  width="100%" height="100%" alt=""/>
            </div> */}
              </div>

              <div class="col-8 col-md-8">
                <div class="row-4">
                  <Link
                    to="/top-shopify-marketing-strategy-for-sellers"
                    style={{ textDecoration: "none" }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        fontFamily: "poppins",
                        color: "black",
                      }}
                    >
                      Top Shopify Marketing Strategy For Sellers
                    </p>
                  </Link>
                </div>
                <div class="row-8">
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "100",
                      opacity: "0.6",
                    }}
                  >
                    E-commerce platforms like Shopify are ultimate friends of
                    small business owners. The platform allows you to build your
                    own website and shine in the world
                  </p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-4 col-md-4">
                <div class="row-8">
                  <img
                    src="../../assets/images/Blogpost.jpg"
                    width="100%"
                    height="100%"
                    alt=""
                  />
                </div>

                {/* <div class="row-4"style={{marginTop:"37%"}}>
            <img src="assets/images/latestblog.jpg"  width="100%" height="100%" alt=""/>
            </div> */}
              </div>

              <div class="col-8 col-md-8">
                <div class="row-4">
                  <Link
                    to="/5-important-photography-tips-for-beginners"
                    style={{ textDecoration: "none" }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        fontFamily: "poppins",
                        color: "black",
                      }}
                    >
                      5 Important Photography Tips For Beginners
                    </p>
                  </Link>
                </div>
                <div class="row-8">
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "100",
                      opacity: "0.6",
                    }}
                  >
                    Photographs help us freeze a certain moment and then relive
                    it again and again. It enables us to capture our happiest
                    memories into a frame
                  </p>
                </div>
              </div>
            </div>
            <br />
            <br />
            <p
              style={{
                fontWeight: "700",
                fontSize: "20px",
                fontFamily: "poppins",
              }}
            >
              Share:
            </p>
            <hr
              style={{
                width: "80%",
                border: "3px solid",
                backgroundColor: "black",
                opacity: "1",
                marginTop: "-3%",
              }}
            ></hr>

            <div class="row">
              <div class="col-4 col-md-4">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://rayyanmustaqbil.xyz/5-important-photography-tips-for-beginners/"
                  target="_blank"
                >
                  <img
                    src="../../assets/images/facebookblack.png"
                    height="100%"
                    width="50%"
                    alt=""
                  />
                </a>
              </div>

              <div class="col-4 col-md-4">
                <a
                  href="https://twitter.com/intent/tweet?text=%20https://rayyanmustaqbil.xyz/5-important-photography-tips-for-beginners/"
                  target="_blank"
                >
                  {" "}
                  <img
                    src="../../assets/images/twit.png"
                    height="100%"
                    width="50%"
                    alt=""
                  />
                </a>
              </div>

              <div class="col-4 col-md-4">
                <a
                  href="    https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2FshareArticle%3Fmini%3Dtrue%26url%3Dhttps%3A%2F%2Frayyanmustaqbil.xyz%2F5-important-photography-tips-for-beginners%2F%26title%3D5%2520Important%2520Photography%2520Tips%2520for%2520Beginners%2520%25E2%2580%2593%2520CutOutWiz%26summary%3D%26source%3Dhttps%3A%2F%2Frayyanmustaqbil.xyz%2F5-important-photography-tips-for-beginners%2F"
                  target="_blank"
                >
                  {" "}
                  <img
                    src="../../assets/images/linkdn.png"
                    height="100%"
                    width="50%"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <p
        style={{
          textAlign: "center",
          color: "white",
          padding: "8px",
          backgroundColor: "black",
        }}
      >
        Designed And Developed By Rayyan Mustaqbil
      </p>
    </div>
  );
}

export default OutsourcePhoto;
